import { css } from '@emotion/css';

export const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 30px;
`;

export const subContainer = css`
  width: 80%;
`;

export const cardSection = css`
  margin: 36px 0;
`;

export const cardList = css`
  display: flex;
  padding: 10px;
  height: 230px;
  overflow-x: scroll;
  @media (min-width: 420px) {
    height: 400px;
  }
`;

export const buttonForm = css`
  background: #7600d3;
`;
