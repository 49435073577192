import { css } from '@emotion/css';

export const card = css`
  width: 110px;
  height: 190px;
  margin-inline-end: 15px;
  @media (min-width: 420px) {
    width: 215px;
    height: 350px;
  }
`;

export const imageContainer = css`
  overflow: hidden;
  height: 100px;
  @media (min-width: 420px) {
    height: 215px;
  }
`;

export const textProductName = css`
  padding: 0px;
  font-size: 14px;
`;
