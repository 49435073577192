/* eslint-disable @next/next/no-img-element */
import { Card, Typography } from 'antd';
import Link from 'next/link';

import * as css from './EntryCard.styles';
import type { EntryCardProps } from './EntryCard.types';

function EntryCard(props: EntryCardProps) {
  const { isSeller = false, redirectId, title, image } = props;

  const sellRedirect = `/product/sell/${redirectId}`;
  const buyRedirect = `/product/buy/${redirectId}`;

  return (
    <Link href={isSeller ? sellRedirect : buyRedirect}>
      <Card
        hoverable
        className={css.card}
        cover={
          <div className={css.imageContainer}>
            <img
              src={image ? image : '/No_Image_Available.jpeg'}
              alt="card image"
              style={{ width: '100%' }}
            />
          </div>
        }
      >
        <Typography.Text className={css.textProductName}>
          {title.slice(0, 20)}
        </Typography.Text>
      </Card>
    </Link>
  );
}

export default EntryCard;
