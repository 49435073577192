import EntryCard from '@/components/EntryCard';
import NavButton from '@/components/NavButton';
import { Col, Row, Typography } from 'antd';
import { useEffect } from 'react';

import Banner from './Banner';
import * as css from './Home.styles';
import useFetchHome from './queries/useFetchHome';

function Home() {
  const { data, isLoading, error } = useFetchHome();

  useEffect(() => {
    console.log('HOME', data);
  }, [data]);

  return (
    <div className={css.container}>
      <div className={css.subContainer}>
        <Banner />
        <Typography.Title>Saya mau...</Typography.Title>
        <Row gutter={8} style={{ marginBottom: 8 }}>
          {/* <Col span={24}>
            <Input.Search enterButton={<><SearchOutlined/> Cari Sekarang</>}
      size="large" placeholder="Apa yang ingin kau cari?"/>
          </Col> */}
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <NavButton
              href="/create/buy"
              size="large"
              type="primary"
              block
              className={css.buttonForm}
            >
              Beli barang
            </NavButton>
          </Col>
          <Col span={12}>
            <NavButton
              href="/create/sell"
              size="large"
              type="primary"
              block
              className={css.buttonForm}
            >
              Jual barang
            </NavButton>
          </Col>
        </Row>

        <div className={css.cardSection}>
          <Typography.Title>Yang Orang Mau Beli</Typography.Title>
          <div className={css.cardList}>
            {data.buyList.map((item) => (
              <EntryCard
                key={item.id}
                redirectId={item.id}
                title={item.itemName}
                user={item.username}
                image={item.image}
              />
            ))}
          </div>
        </div>


        <div className={css.cardSection}>
          <Typography.Title>Yang Orang Mau Jual</Typography.Title>
          <div className={css.cardList}>
            {data.sellList.map((item) => (
              <EntryCard
                key={item.id}
                isSeller
                redirectId={item.id}
                title={item.itemName}
                user={item.username}
                image={item.image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
