/* eslint-disable @next/next/no-img-element */
import { css } from '@emotion/css';
import { Image } from 'antd';
import { useEffect, useRef, useState } from 'react';

const images = ['/WTB.png', '/WTS.png'];
function Banner() {
  const [selected, setSelected] = useState(0);
  const timerRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setSelected((prev) => (prev + 1) % images.length);
    }, 4000);

    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, []);

  return (
    <div>
      <div className={flexCenter}>
        <div className={banner}>
          <Image
            preview={false}
            src={images[selected]}
            alt="banner"
            className={image}
          />
        </div>
      </div>
      <div className={circleContainer}>
        {images.map((_, idx) => (
          <div
            key={`banner-dot-${idx}`}
            className={idx === selected ? activeCircle : circle}
          />
        ))}
      </div>
    </div>
  );
}

export default Banner;

const flexCenter = css`
  display: flex;
  justify-content: center;
`;

const banner = css`
  width: 100%;
  height: auto;
  @media (min-width: 420px) {
    border-radius: 30px;
    width: 700px;
    heigth: auto;
  }
`;

const image = css`
  border-radius: 10px;
  @media (min-width: 420px) {
    border-radius: 30px;
  }
`;

const circleContainer = css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const activeCircle = css`
  background-color: #7600d3;
  width: 10px;
  height: 10px;
  border-radius: 999px;
  margin: 5px;
`;

const circle = css`
  background-color: gray;
  width: 8px;
  height: 8px;
  border-radius: 999px;
  margin: 5px;
`;
