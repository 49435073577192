import { useEffect, useState } from 'react';
import axios from 'axios';

import type { FetchHomeResponse } from './useFetchHome.types';
import { PATH } from './useFetchHome.constants';

const normalizeData = (
  data: FetchHomeResponse | undefined,
): FetchHomeResponse => {
  const sellList = data?.sellList || [];
  const buyList = data?.buyList || [];

  const tempSellList = [];
  const tempBuyList = [];

  for (const item of sellList) {
    tempSellList.push({
      id: item?.id || '',
      username: item?.username || '',
      image: item?.image || '',
      itemName: item?.itemName || '',
    });
  }

  for (const item of buyList) {
    tempBuyList.push({
      id: item?.id || '',
      username: item?.username || '',
      image: item?.image || '',
      itemName: item?.itemName || '',
    });
  }

  return {
    sellList: tempSellList,
    buyList: tempBuyList,
  };
};

const useFetchHome = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<FetchHomeResponse>(
    normalizeData(undefined) as FetchHomeResponse,
  );
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    setIsLoading(true);
    console.log('URL Dashboard:', PATH);

    axios
      .get<FetchHomeResponse>(PATH)
      .then((data) => {
        console.log('INSIDE AXIOS', data.data);
        setData(data.data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  return { data: data, isLoading, error };
};

export default useFetchHome;
