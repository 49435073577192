import Head from 'next/head';

import Home from '@/sections/Home';
import Script from 'next/script';

export default function HomePage() {
  return (
    <>
      <Head>
        <title>Hellow Indonesia</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/icon.ico" />
      </Head>

      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-11159884475"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-11159884475');
        `}
      </Script>
      <Home />
    </>
  );
}
