import React, { forwardRef } from 'react';
import Link from 'next/link';

import { Button } from 'antd';
import type { ButtonProps } from 'antd';

import type { NavButtonProps } from './NavButton.types';

/**
 * Button that navigates to a next link
 * @date 12/9/2022 - 10:40:51 AM
 *
 * @param {NavButtonProps} props
 */
function NavButton(props: NavButtonProps) {
  const { href, children, ...restProps } = props;

  // eslint-disable-next-line react/display-name
  const WrappedButton = forwardRef<HTMLElement, ButtonProps>((props, ref) => (
    <Button {...props} ref={ref}>
      {children}
    </Button>
  ));

  return (
    <Link href={href} passHref legacyBehavior>
      <WrappedButton {...restProps} />
    </Link>
  );
}

export default NavButton;
